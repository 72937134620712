<template>
  <section id="edit-products-shop">
    <b-tabs
      v-if="mostrarDatos"
      pills
    >
      <!-- INFO -->
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>{{ $t("contents.categoryData") }}</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showData"
            >
              <b-card>
                <b-form @submit.prevent="updateProductsData">
                  <b-card-header>
                    <b-card-title>{{ $t("contents.categoryData") }}</b-card-title>
                    <div>
                      <b-button
                        type="submit"
                        variant="success"
                      >
                        {{ $t("dataGeneric.save") }}
                      </b-button>
                    </div>
                  </b-card-header>
                  <b-card-body>
                    <b-row>
                      <b-col
                        lg="11"
                        class="ml-2 mb-2"
                      >
                        <!-- form input -->
                        <b-form-group
                          class="mb-2"
                          :label="$t('firstName')+' *'"
                        >
                          <b-form-input
                            v-model="name"
                            required
                            :placeholder="$t('nameProduct')"
                            maxlength="150"
                          />
                        </b-form-group>
                        <b-form-group
                          class="mb-2"
                          :label="$t('category.shortDescription') + (language == base ? ' *' : '')"
                        >
                          <b-form-input
                            v-model="shortDescription"
                            :placeholder="$t('product.shortDescription')"
                            maxlength="180"
                            :required="language == base"
                          />
                        </b-form-group>
                        <b-form-group
                          class="mb-2"
                          :label="$t('dataGeneric.description')"
                        >
                          <b-form-textarea
                            v-model="description"
                            :placeholder="$t('dataGeneric.description')"
                          />
                        </b-form-group>

                        <b-form-row v-if="language == base">
                          <b-col>
                            <b-form-group :label="$t('message.tableHeader.status')">
                              <b-form-select
                                id="contentStatus"
                                v-model="contentStatus"
                                :label="$t('message.tableHeader.status')"
                              >
                                <b-form-select-option
                                  v-for="s in status"
                                  :key="s.value"
                                  :value="s.value"
                                >
                                  {{ s.text }}
                                </b-form-select-option>
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row
                          v-if="language == base"
                          class="align-items-center"
                        >
                          <b-col
                            v-if="typeShop != 'INT'"
                            class="mr-1"
                          >
                            <b-form-group :label="$t('externalRef')">
                              <b-form-input
                                id="reference"
                                v-model="reference"
                                maxlength="150"
                                disabled
                                value="reference"
                                :placeholder="$t('externalRefProduct')"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group :label="$t('weight')">
                              <b-form-input
                                id="weight"
                                v-model.number="weight"
                                type="number"
                                step="0.01"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group :label="$t('ancho')">
                              <b-form-input
                                id="width"
                                v-model.number="width"
                                type="number"
                                :placeholder="$t('ancho')"
                                step="0.01"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group :label="$t('alto')">
                              <b-form-input
                                id="high"
                                v-model.number="high"
                                type="number"
                                :placeholder="$t('alto')"
                                step="0.01"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group :label="$t('largo')">
                              <b-form-input
                                id="long"
                                v-model.number="long"
                                type="number"
                                :placeholder="$t('largo')"
                                step="0.01"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row
                          v-if="language == base"
                          class="align-items-center"
                        >
                          <b-col>
                            <b-form-group :label="$t('salePrice') + '  ( ' + currencyType[currency] + ' )'">
                              <b-form-input
                                id="price"
                                v-model.number="price"
                                type="number"
                                :placeholder="$t('salePrice')"
                                step="0.01"
                                class="mr-1"
                                :disabled="typeShop != 'INT'"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col v-if="typeShop == 'INT'">
                            <b-form-group :label="$t('priceOld') + '  ( ' + currencyType[currency] + ' )'">
                              <b-form-input
                                id="priceOld"
                                v-model.number="priceOld"
                                type="number"
                                :placeholder="$t('priceOld')"
                                step="0.01"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col v-if="typeShop == 'INT'">
                            <b-form-group :label="$t('stock')">
                              <b-form-input
                                id="stock"
                                v-model.number="stock"
                                type="number"
                                :placeholder="$t('stock')"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group
                              :label="$t('contents.reference')"
                              class="mb-2"
                            >
                              <b-form-input
                                id="reference"
                                v-model="reference"
                                :placeholder="$t('externalRefProduct')"
                                maxlength="150"
                              />
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row class="align-items-center">
                          <b-col md="11">
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('productTags')"
                            >
                              <v-select
                                v-model="selected"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                multiple
                                taggable
                                push-tags
                                :placeholder="$t('addTagsC')"
                                @input="modalTags()"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <feather-icon
                              v-b-tooltip.hover.top.v-primary="$t('addTags')
                              "
                              icon="InfoIcon"
                            />
                          </b-col>
                        </b-form-row>
                        <b-form-row
                          v-if="language == base"
                          class="align-items-center"
                        >
                          <b-col>
                            <b-form-group :label="$t('VendProd')">
                              <div @click="$refs['vend-modal'].show()">
                                <b-form-input
                                  id="category"
                                  v-model="vendorName"
                                  autocomplete="new-password"
                                  placeholder="vendedor"
                                  disabled
                                />
                              </div>
                            </b-form-group>
                          </b-col>

                          <b-col
                            md="1"
                            class="align-items-center mt-1"
                          >
                            <b-button
                              variant="info"
                              @click="$refs['vend-modal'].show()"
                            >
                              {{ $t('Select') }}
                            </b-button>
                          </b-col>
                        </b-form-row>
                        <b-form-row>
                          <b-col>
                            <b-form-checkbox
                              v-model="isSpecial"
                              switch
                              disabled
                            >
                              {{ $t('isSpecialProduct') }}
                            </b-form-checkbox>
                          </b-col>
                        </b-form-row>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-form>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>

      <!-- IMAGENES -->
      <b-tab>
        <template #title>
          <feather-icon icon="FileIcon" />
          <span>{{ $t("category.filesContent") }}</span>
        </template>
        <b-overlay
          variant="transparent"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
          :show="showDesign"
        >
          <b-card>
            <b-card-header>
              <b-card-title>{{ $t("category.filesContent") }}</b-card-title>
              <div>
                <b-button
                  variant="success"
                  @click="updateProductImages"
                >
                  {{
                    $t("dataGeneric.save")
                  }}
                </b-button>
              </div>
            </b-card-header>
            <b-row>
              <b-col
                lg="12"
                class="ml-2 mb-2"
              >
                <!-- <b-form> -->
                <validation-observer
                  ref="ArchivosRules"
                  tag="form"
                >
                  <b-form-group
                    v-if="language == base"
                    class="mb-2 mr-1"
                    :label="$t('resources.originMedia')"
                  >
                    <b-form-select
                      v-model="mediaOriginSelected"
                      class="mr-1"
                      :options="mediaOriginOptions"
                    />
                  </b-form-group>
                  <div>
                    <!-- FILA TV/WEB -->
                    <b-form-group class="m-1">
                      <label><strong>TV/Web</strong></label>
                      <b-row
                        style="justify-content: space-around;;"
                        align-v="stretch"
                      >
                        <image-element-vue
                          :image-origin="mediaOriginSelected == 'BUK' ? imageProductSelected : imageDataUrl"
                          :media-origin-selected="mediaOriginSelected"
                          :image-type="'imageData' + language"
                          :is-required="true"
                          :label="$t('contents.imgInternal')"
                          @saveImage="loadImage"
                        />
                        <image-element-vue
                          :image-origin="mediaOriginSelected == 'BUK' ? backgroundProductSelected : imageDataBckUrl"
                          :media-origin-selected="mediaOriginSelected"
                          :image-type="'imageDataBck' + language"
                          :label-info="$t('infoImage.background')"
                          :label="$t('contents.bckgInternal')"
                          :can-delete="true"
                          @saveImage="loadImage"
                          @deleteImage="deleteImage"
                        />
                        <b-col cols="3" />
                      </b-row>
                    </b-form-group>
                    <!-- FILA MÓVIL -->
                    <b-form-group class="m-1">
                      <label><strong>{{ $t('Movil') }}</strong></label>
                      <b-row style="justify-content: space-around;;">
                        <image-element-vue
                          :image-origin="mediaOriginSelected == 'BUK' ? mobileImageProductSelected : imageDataMobUrl"
                          :media-origin-selected="mediaOriginSelected"
                          :image-type="'imageDataMob' + language"
                          :label="$t('contents.mobileImgInternal')"
                          :can-delete="true"
                          @saveImage="loadImage"
                          @deleteImage="deleteImage"
                        />
                        <b-col cols="7" />
                      </b-row>
                    </b-form-group>
                  </div>
                </validation-observer>
                <!-- </b-form> -->
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
      </b-tab>

      <!-- SEO -->
      <b-tab v-if="language == base">
        <template #title>
          <feather-icon icon="Edit2Icon" />
          <span>SEO</span>
        </template>
        <b-overlay
          variant="transparent"
          :show="showSeo"
        >
          <b-card>
            <b-card-header>
              <b-card-title>SEO</b-card-title>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col
                  lg="12"
                  class="ml-2 mb-2"
                >
                  <b-form @submit.prevent="updateSeo">
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('title')"
                    >
                      <b-form-input
                        v-model="seoTitle"
                        :placeholder="$t('title')"
                        class="mr-1"
                        maxlength="80"
                      />
                    </b-form-group>

                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('dataGeneric.description')"
                    >
                      <b-form-input
                        v-model="seoDescription"
                        :placeholder="$t('dataGeneric.description')"
                        maxlength="180"
                        class="mr-1"
                      />
                    </b-form-group>
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('friendUrl')"
                    >
                      <b-form-input
                        v-model="friendlyUrl"
                        :placeholder="$t('friendUrl')"
                        class="mr-1"
                      />
                    </b-form-group>

                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('converFriendUrl') + ':'"
                    >
                      {{ friendlyUrlValue }}
                    </b-form-group>

                    <b-button
                      type="submit"
                      variant="success"
                    >
                      {{
                        $t("dataGeneric.save")
                      }}
                    </b-button>
                  </b-form>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-tab>
    </b-tabs>

    <b-modal
      ref="modalCard"
      size="xl"
      hide-footer
      :title="$t('modal.card')"
    >
      <div class="d-block text-center">
        <card-selector-modal
          :seleccionado="idSelectCard"
          @id="SelectCardId"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="vend-modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <vendor-selector @vendor="selectVendor" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>

import {
  showToast, messageError, camelToSnake,
  limitCharacter, camelToKebab,
} from '@/store/functions'

import {
  BForm,
  BFormCheckbox,
  BFormInput,
  BFormSelectOption,
  BFormGroup,
  BFormRow,
  BFormTextarea,
  BRow,
  BCol,
  BCard,
  BButton,
  BFormSelect,
  BTabs, BTab,
  BCardHeader,
  BCardBody,
  VBTooltip,
  BCardTitle,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import { ValidationObserver } from 'vee-validate'
import ImageElementVue from '@/views/components/ImageElement.vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import VendorSelector from '../../../common/VendorSelectorModal.vue'
import CardSelectorModal from '../../../common/CardSelectorModal.vue'

export default {
  components: {
    BRow,
    BTabs,
    BTab,
    BCol,
    BCard,
    BButton,
    VendorSelector,
    BFormSelect,
    BForm,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BFormSelectOption,
    BFormRow,
    BFormTextarea,
    CardSelectorModal,
    BCardHeader,
    BCardBody,
    vSelect,
    BCardTitle,
    BOverlay,
    ValidationObserver,
    ImageElementVue,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    language: {
      type: String,
      default: null,
    },
    base: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      viewName: this.$t('editProductShop'),
      viewDetail: this.$t('configProductShop'),
      isSpecial: false,
      vendorName: null,
      vendorId: null,
      currencyType: {
        EUR: '€',
        USD: '$',
        GBP: '£',
        ARS: 'Argentine peso',
      },
      currency: null,
      typeShop: null,
      labelStatus: 'RED',
      contentStatus: 'RED',
      price: null,
      priceOld: null,
      stock: null,
      weight: null,
      width: null,
      long: null,
      high: null,
      deleteFile: [],
      status: [
        { value: 'RED', text: `🔴 ${this.$t('code.desactivado')}` },
        { value: 'YEW', text: `🟡 ${this.$t('dataGeneric.internal')}` },
        { value: 'GRE', text: `🟢 ${this.$t('ecommerce.public')}` },
      ],
      userData: getUserData(),
      id: this.$route.params.id,
      idSelectCard: null,
      isActive: false,
      isBackgroundBlur: false,
      isBackgroundKenBurns: false,
      name: '',
      description: '',
      shortDescription: '',
      reference: '',
      mediaOriginSelected: 'BUK',
      mediaOriginOptions: [
        { value: 'BUK', text: this.$t('dataGeneric.internal') },
        { value: 'DIR', text: this.$t('dataGeneric.external') },
      ],
      mostrarDatos: false,
      cardSelected: null,
      cardMobileSelected: null,
      showData: false,
      showDesign: false,
      selected: [],
      idTags: [],
      originalTags: [],
      option: [],
      friendlyUrl: null,
      friendlyUrlValue: null,
      seoTitle: null,
      seoDescription: null,
      showSeo: false,
      imageProductSelected: false,
      backgroundProductSelected: false,
      mobileImageProductSelected: false,
      imageData: null,
      imageDataMob: null,
      imageDataBck: null,
      imageDataUrl: '',
      imageDataBckUrl: '',
      imageDataMobUrl: '',
    }
  },
  watch: {
    friendlyUrl() {
      this.friendlyUrlValue = this.friendlyUrl
        // eslint-disable-next-line no-useless-escape
        .replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ')
        .toLowerCase()

      // Corta los espacios al inicio y al final del this.friendlyUrling
      this.friendlyUrlValue = this.friendlyUrlValue.replace(/^\s+|\s+$/gm, '')

      // Reemplaza el espacio con guión
      this.friendlyUrlValue = this.friendlyUrlValue.replace(/\s+/g, '-')
    },
  },
  mounted() {
    this.getTags()
    this.fetch()
  },
  methods: {
    selectVendor(data) {
      this.hideModal()
      this.vendorId = data.id
      this.vendorName = data.vendorName
    },
    hideModal() {
      this.$refs.modalCard.hide()
      this.$refs['vend-modal'].hide()
    },
    showModalCard(type) {
      this.$refs.modalCard.show()
      switch (type) {
        case 'imageCatTV':
          this.idSelectCard = this.cardSelected

          break
        case 'imageContTV':
          this.idSelectCard = this.cardMobileSelected

          break

        default:
          break
      }
      this.typeCard = type
    },
    fetch() {
      const query = `
        query {        
          allProducts(id:"${this.id}") {                
            edges {
              node {
                id
                name(lang:"${this.language}")
                order
                shortDescription(lang:"${this.language}")
                friendlyUrl
                seoTitle
                seoDescription
                reference
                state
                isActive     
                isSpecial     
                mediaLocation
                description(lang:"${this.language}")                    
                imageUrl(lang:"${this.language}")
                imageMobileUrl(lang:"${this.language}")
                backgroundUrl(lang:"${this.language}")
                vendor{
                  id
                  vendorName
                }
                categories {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                tags {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }        
                price
                priceOld
                stock
                width
                long                        
                weight
                high
                isBackgroundBlur
                isBackgroundKenBurns
                tags {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                client {
                  id
                  name
                  currency
                  typeShop
                }       
              }
            }
          }
        }
      `
      axios.post('', { query }).then(res => {
        messageError(res, this)

        const datos = res.data.data.allProducts.edges[0].node
        this.name = datos.name
        this.shortDescription = datos.shortDescription
        this.reference = datos.reference
        this.price = datos.price
        this.priceOld = datos.priceOld
        this.stock = datos.stock
        this.width = datos.width
        this.high = datos.high
        this.long = datos.long
        this.weight = datos.weight
        this.vendorId = datos.vendor != null ? datos.vendor.id : null
        this.vendorName = this.vendorId != null ? datos.vendor.vendorName : null
        this.isSpecial = datos.isSpecial

        this.seoTitle = datos.seoTitle
        this.seoDescription = datos.seoDescription
        this.friendlyUrl = datos.friendlyUrl

        this.currency = datos.client.currency
        this.typeShop = datos.client.typeShop
        this.isActive = datos.isActive
        this.description = datos.description
        this.shortDescription = datos.shortDescription
        this.isBackgroundBlur = datos.isBackgroundBlur
        this.isBackgroundKenBurns = datos.isBackgroundKenBurns
        this.contentStatus = datos.state
        this.labelStatus = datos.state

        this.mediaOriginSelected = datos.mediaLocation
        this.mostrarDatos = true

        this.selected = []
        this.idTags = []
        this.originalTags = []
        datos.tags.edges.forEach(element => {
          this.selected.push(element.node.name)
          this.idTags.push(element.node.id)
          this.originalTags.push(element.node.name)
        })

        if (this.mediaOriginSelected === 'DIR') {
          this.imageDataUrl = datos.imageUrl
          this.imageDataBckUrl = datos.backgroundUrl
          this.imageDataMobUrl = datos.imageMobileUrl
        } else {
          this.imageProductSelected = datos.imageUrl
          this.backgroundProductSelected = datos.backgroundUrl
          this.mobileImageProductSelected = datos.imageMobileUrl
        }
        this.$emit('return', {
          name: this.name, viewName: this.viewName, viewDetail: this.viewDetail, state: this.labelStatus,
        })
      }).catch(err => {
        console.log(err)
      })
    },
    SelectCardId(file) {
      switch (this.typeCard) {
        case 'imageCatTV':
          this.cardSelected = file
          break
        case 'imageContTV':
          this.cardMobileSelected = file
          break
        default:
          break
      }
      this.hideModal()
    },
    updateProductsData(event) {
      const headers = {
        'Accept-Language': camelToKebab(this.language),
        'Content-Language': camelToKebab(this.language),
      }
      this.showData = true
      event.preventDefault()

      this.isActive = !!(this.contentStatus === 'GRE' || this.contentStatus === 'YEW')

      let objectVariables = []
      objectVariables = [
        { title: `name${this.language}`, value: limitCharacter(this.name, 150), type: 'String' },
        { title: `description${this.language}`, value: this.description, type: 'String' },
        { title: `shortDescription${this.language}`, value: limitCharacter(this.shortDescription, 180), type: 'String' },
      ]
      if (this.language === this.base) {
        objectVariables.push(
          { title: 'reference', value: this.reference, type: 'String' },
          { title: 'stock', value: this.stock, type: 'Int' },
          { title: 'isSpecial', value: this.isSpecial, type: 'Boolean' },
          { title: 'long', value: this.long, type: 'Float' },
          { title: 'high', value: this.high, type: 'Float' },
          { title: 'width', value: this.width, type: 'Float' },
          { title: 'price', value: this.price, type: 'Float' },
          { title: 'priceOld', value: this.priceOld, type: 'Float' },
          { title: 'weight', value: this.weight, type: 'Float' },
          { title: 'isActive', value: this.isActive, type: 'Boolean' },
          { title: 'isBackgroundBlur', value: this.isBackgroundBlur, type: 'Boolean' },
          { title: 'isBackgroundKenBurns', value: this.isBackgroundKenBurns, type: 'Boolean' },
          { title: 'state', value: this.contentStatus, type: 'ShowroomProductStateChoices' },
          { title: 'vendor', value: this.vendorId, type: 'ID' },
        )
      }
      const variables = {}
      let mutation = 'mutation('
      let query = `{updateProductsShop(id:"${this.id}",input:{\n
        tags: [${this.idTags}]\n`
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `
        }) {
          product {
            id
            name
            description
            shortDescription
            reference
            isActive           
          }
        }
      }`
      query = mutation + query
      axios
        .post('', {
          variables,
          query,
        }, { headers })
        .then(res => {
          messageError(res, this)

          this.labelStatus = this.contentStatus
          showToast(
            this.$t('success'),
            1, this,
          )
          this.showData = false
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 2, this)
          this.showData = false
        })
    },
    goBack() {
      window.history.back()
    },
    updateProductImages(event) {
      return new Promise(resolve => {
        this.$refs.ArchivosRules.validate().then(success => {
          const headers = {
            'Accept-Language': camelToKebab(this.language),
            'Content-Language': camelToKebab(this.language),
          }
          const pass = !!(this.mediaOriginSelected === 'BUK' && this.imageProductSelected)

          if (success || pass) {
            resolve(true)
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()
            this.showDesign = true
            event.preventDefault()

            const mediaLocation = this.mediaOriginSelected === 'BUK' ? 'BUK' : 'DIR'
            if (mediaLocation === 'BUK') {
              this.imageDataUrl = ''
              this.imageDataMobUrl = ''
              this.imageDataBckUrl = ''
            }

            const objectVariables = [
              { title: `imageUrl${this.language}`, value: this.imageDataUrl, type: 'String' },
              { title: `imageMobileUrl${this.language}`, value: this.imageDataMobUrl, type: 'String' },
              { title: `backgroundUrl${this.language}`, value: this.imageDataBckUrl, type: 'String' },
            ]

            const variables = {}
            let mutation = 'mutation('
            let query = `{
              updateProductsShop (id:"${this.id}", input:{
                mediaLocation: ${mediaLocation},
            `
            objectVariables.forEach(element => {
              variables[element.title] = element.value
              mutation += `$${element.title}:${element.type},\n`
              query += `${element.title}:$${element.title},\n`
            })
            mutation += ')'
            query += `}){
                  product{
                    id
                    name
                    ${mediaLocation === 'BUK' ? 'image\nimageMobile\nbackground\n' : ''}
                  }
                }
              }
            `
            query = mutation + query

            data.append('query', query)
            data.append('variables', JSON.stringify(variables))

            if (mediaLocation === 'BUK') {
              data.append(`image_${camelToSnake(this.language)}`, this.imageData)
              data.append(`image_mobile_${camelToSnake(this.language)}`, this.imageDataMob)
              data.append(`background_${camelToSnake(this.language)}`, this.imageDataBck)
            }

            axios
              .post('', data, { headers }, config)
              .then(res => {
                messageError(res, this)
                this.showDesign = false
                showToast(this.$t('code.updateData'), 1, this)
              })
              .catch(() => {
                this.showDesign = false
                showToast(this.$t('code.updateDataError'), 2, this)
              })
          }
        })
      })
    },
    deleteImage(type) {
      const headers = {
        'Accept-Language': camelToKebab(this.language),
        'Content-Language': camelToKebab(this.language),
      }
      this.$swal({
        title: this.$t('removData'),
        text: this.$t('sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.showDesign = true
            this.deleteFile.push(type)
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()
            if (this.mediaOriginSelected === 'BUK') {
              const query = `
                mutation{
                  updateProductsShop(id:"${this.id}", input:{ mediaLocation: BUK }){
                    product{
                      id
                      name
                    }
                  }
                }
              `
              data.append('query', query)
              switch (type) {
                case `imageDataMob${this.language}`:
                  this.mobileImageProductSelected = null
                  data.append('deleteFile', `image_mobile_${camelToSnake(this.language)}`)
                  break
                case `imageDataBck${this.language}`:
                  this.backgroundProductSelected = null
                  data.append('deleteFile', `background_${camelToSnake(this.language)}`)
                  break
                default:
                  break
              }
              axios.post('', data, { headers }, config).then(createResponse => {
                messageError(createResponse, this)
                this.showDesign = false
                showToast(this.$t('success'), 1, this)
              }).catch(() => {
                this.showDesign = false
                showToast(this.$t('code.updateDataError'), 2, this)
              })
            } else {
              let objectVariables = []

              switch (type) {
                case 'imageDataMob':
                  this.imageDataMobUrl = ''
                  objectVariables = [{
                    title: `imageMobileUrl${this.language}`,
                    value: this.imageDataMobUrl,
                    type: 'String',
                  }]
                  break
                case 'imageDataBck':
                  this.imageDataBckUrl = ''
                  objectVariables = [{
                    title: `backgroundUrl${this.language}`,
                    value: this.imageDataBckUrl,
                    type: 'String',
                  }]
                  break
                default:
                  break
              }

              const variables = {}
              let mutation = 'mutation('
              let query = `{
                updateProductsShop(id:"${this.id}", input:{
                  mediaLocation: DIR,
              `
              objectVariables.forEach(element => {
                variables[element.title] = element.value
                mutation += `$${element.title}:${element.type},\n`
                query += `${element.title}:$${element.title},\n`
              })
              mutation += ')'
              query += `}){
                    product{
                      id
                      name            
                    }
                  }
                }
              `
              query = mutation + query
              data.append('query', query)
              data.append('variables', JSON.stringify(variables))

              axios
                .post('', data, { headers }, config)
                .then(res => {
                  messageError(res, this)

                  this.showDesign = false

                  showToast(this.$t('code.updateData'), 1, this)
                })
                .catch(error => {
                  console.log(error)
                  this.showDesign = false
                  showToast(this.$t('code.updateDataError'), 2, this)
                })
            }
          }
        })
        .catch(() => { })
    },
    modalTags() {
      if (this.selected.length > this.idTags.length) {
        let id = ''
        axios
          .post('', {
            query: `
      {
        allTags(name:"${this.selected[this.selected.length - 1]}") {
          edges {
            node {
              id
              name
              createdAt

            }
          }
        }
      }
    `,
          })
          .then(result => {
            messageError(result, this)

            let crear = true
            result.data.data.allTags.edges.forEach(element => {
              if (
                element.node.name.toLowerCase()
                === this.selected[this.selected.length - 1].toLowerCase()
              ) {
                id = element.node.id
                crear = false
              }
            })

            if (crear) {
              /* AQUÍ CREA EL TAG */

              axios
                .post('', {
                  query: `
          mutation{
            createTag(input:{name:"${this.selected[this.selected.length - 1]
}"}){
                tag{
                    id
                    name
                }
            }
        }
        `,
                })
                .then(resizeBy => {
                  messageError(resizeBy, this)

                  this.idTags.push(resizeBy.data.data.createTag.tag.id)
                })
                .catch(() => { })
            } else {
              this.idTags.push(id)
            }
          })
          .catch(() => { })
      } else {
        // console.log(this.selected);
        this.originalTags.forEach((a, index) => {
          let quitar = true
          this.selected.forEach(element => {
            if (element.toLowerCase() === a.toLowerCase()) {
              quitar = false
            }
          })
          if (quitar) {
            this.idTags.splice(index, 1)
            this.originalTags.splice(index, 1)
          }
        })
      }
    },
    getTags() {
      axios
        .post('', {
          query: `
      {
        allTags {
          edges {
            node {
              id
              name
              createdAt

            }
          }
        }
      }
    `,
        })
        .then(result => {
          messageError(result, this)

          result.data.data.allTags.edges.forEach(element => {
            this.option.push({
              title: element.node.name,
              value: element.node.id,
            })
          })
        })
        .catch(() => { })
    },
    updateSeo(event) {
      this.showSeo = true
      event.preventDefault()
      const { id } = this.$route.params

      axios
        .post('', {
          variables: {
            title: this.seoTitle,
            description: this.seoDescription,
            friendlyUrl: this.friendlyUrlValue,
          },
          query: `
        mutation($title: String,$description: String,$friendlyUrl: String){
          updateProductsShop(id:"${id}", input:{
          seoTitle: $title,
          seoDescription: $description,
          friendlyUrl: $friendlyUrl
          }){
            product{
            id
            seoTitle
            seoDescription
            friendlyUrl
          }
        }
      }
    `,
        })
        .then(res => {
          messageError(res, this)

          showToast(
            this.$t('code.cat', {
              data: res.data.data.updateProductsShop.product.name,
            }),
            1, this,
          )

          this.showSeo = false
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 2, this)
          this.showSeo = false
        })
    },
    newTag(data) {
      this.option.push({
        title: data.name,
        value: data.id,
      })
      this.selected.push({
        title: data.name,
        value: data.id,
      })
    },
    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case `imageData${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.imageData = data
          else this.imageDataUrl = data
          break
        case `imageDataBck${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.imageDataBck = data
          else this.imageDataBckUrl = data
          break
        case `imageDataMob${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.imageDataMob = data
          else this.imageDataMobUrl = data
          break
        default:
          break
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "vue-context/src/sass/vue-context";

#edit-products-shop .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
}

#edit-products-shop #add-new-Product-sidebar {
  #edit-products-shop .vs__dProductmenu {
    max-height: 200px !important;
  }
}

#edit-products-shop .final-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  font-weight: bold;
  color: aquamarine;
  font-size: smaller;
}
</style>
